import { forwardRef } from 'react';
import MediaImage from '@/components/MediaImage';

export const CategoryBannerBlockImg = forwardRef(
  (
    props: {
      display: string | string[];
      media: string;
      onLoad: () => void;
    },
    ref: React.RefCallback<HTMLImageElement>
  ) => {
    return (
      <MediaImage
        data-testid="category-banner-content-block__img"
        className="category-banner-content-block__img"
        alt="category-banner-img"
        height="100%"
        width="100%"
        objectFit="cover"
        ref={ref}
        sourceSetConfig={{
          bpConfig: {
            MD: {
              size: 722,
              quality: 70,
            },
            LG: {
              size: 1440,
              quality: 80,
            },
          },
        }}
        {...props}
      />
    );
  }
);
