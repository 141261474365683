import { makeImageSourceSet, getMediaUrl } from '@/utils/imageUtilities';
import { floral, forwardRef } from '@thebouqs/ui';
import { MediaImageProps } from './MediaImage.d';
import { css } from '@emotion/react';

/**
 * Image component friendly with Cloudflare's configuration and bouqs-cdn /media/{imageUrl}
 * @example
 * <MediaImage alt="Great description" img="https://bouqs-url.com/media/somehash/file.jpg" />
 */
export const MediaImage = forwardRef<MediaImageProps, 'img'>((props, ref) => {
  const { sourceSetConfig, media, ...imageProps } = props;
  const styles = css`
    @media screen and (min-width: 1520px) {
      width: 100%;
    }
  `;

  const srcSet = makeImageSourceSet(getMediaUrl(media), sourceSetConfig);

  return (
    <floral.img
      ref={ref}
      srcSet={srcSet.join()}
      src={media}
      css={styles}
      {...imageProps}
    />
  );
});
