import {
  ReactElement,
  useMemo,
  useState,
  useCallback,
  KeyboardEvent,
} from 'react';
import {
  Button,
  Box,
  Text,
  TextProps,
  ButtonProps,
  Heading,
} from '@thebouqs/ui';
import { downloadStatus } from '@/hooks/useLazyImage/constants';
import MediaImage, { MediaImageProps } from '@/components/MediaImage';
import { convertHexToRgba } from '@/styles/util';
import { MEDIA_QUERIES } from '@/constants/styles';
import { SuperHeroBlockData } from './SuperHeroBlock.d';
import { SuperHeroMediaSkeleton } from './SuperHeroBlockSkeleton';
import { ContentAreaStyles } from '@/components/ContentAreas/ContentArea.styles';

type SuperHeroBlockComponent = Partial<SuperHeroBlockData> & {
  children?: ReactElement | ReactElement[];
};

export function SuperHeroBlockMain(
  props: SuperHeroBlockComponent
): JSX.Element {
  const { media, media_medium, media_small, callToAction, alt_text } = props;

  const hasAdditionalMedia = media_small || media_medium;

  const desktopMediaDisplay = hasAdditionalMedia
    ? [media_small ? 'none' : 'block', media_medium ? 'none' : 'block', 'block']
    : 'block';

  const imageProps = {
    onClick: callToAction,
    onKeyDown: (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        callToAction();
      }
    },
  };

  const imageNodes = hasAdditionalMedia ? (
    <>
      {media_small ? (
        <SuperHeroBlockMedia
          display={['block', 'none']}
          data-testid="super-hero-media-mobile"
          media={media_small}
          key="small"
          sourceSetConfig={{
            bpConfig: {
              SM: {
                size: 480,
                quality: 65,
              },
            },
          }}
          alt={alt_text}
          {...imageProps}
        />
      ) : null}
      {media_medium ? (
        <SuperHeroBlockMedia
          data-testid="super-hero-media-tablet"
          display={['none', 'block', 'none']}
          media={media_medium}
          key="medium"
          sourceSetConfig={{
            bpConfig: {
              MD: {
                size: 768,
                quality: 65,
              },
            },
          }}
          alt={alt_text}
          {...imageProps}
        />
      ) : null}
    </>
  ) : null;

  return (
    <Box
      overflow="hidden"
      className="super-hero-content-block__media-container"
      data-testid="super-hero-media-container"
      my="6"
      position="relative"
      __css={{
        [MEDIA_QUERIES.RESPOND_DOWN.MD]: {
          display: 'flex',
          flexDir: 'column',
          height: '100%',
        },
        ...ContentAreaStyles,
      }}>
      <SuperHeroBlockMedia
        display={desktopMediaDisplay}
        data-testid="super-hero-media-desktop"
        media={media}
        onClick={callToAction}
        key="large"
        sourceSetConfig={{
          bpConfig: {
            SM: {
              size: 480,
              quality: 65,
            },
            MD: {
              size: 768,
              quality: 65,
            },
            LG: {
              size: 992,
              quality: 80,
            },
            XL: {
              size: 1400,
              quality: 80,
            },
          },
        }}
        alt={alt_text}
        {...imageProps}
      />
      {imageNodes}
      {props.children}
    </Box>
  );
}

export function SuperHeroBlockMedia(
  props: MediaImageProps & {
    display: string | string[];
    alt: string;
    onClick: () => void;
    onKeyDown: (e: KeyboardEvent) => void;
    key?: string;
  }
): JSX.Element {
  const [status, setStatus] = useState<string>(downloadStatus.LOADING);
  const onLoad = useCallback(() => {
    setStatus(downloadStatus.OK);
  }, [setStatus]);

  const onMediaRef = useCallback(node => {
    if (!node) {
      return null;
    }

    if (node.complete) {
      // image was acached or prebunddled
      setStatus(downloadStatus.OK);
    }
  }, []);

  return (
    <Box display={props.display} position="relative" width="100%">
      <MediaImage
        {...props}
        key={props.key ? `super-hero-media-${props.key}` : undefined}
        onLoad={onLoad}
        ref={onMediaRef}
        objectFit="cover"
        // @TODO: Wait for the image reference grid to be available to improve `objectPosition`
        objectPosition="center"
        zIndex={0}
        cursor="pointer"
      />
      {status === downloadStatus.LOADING ? <SuperHeroMediaSkeleton /> : null}
    </Box>
  );
}

export function SuperHeroBlockContent(
  props: SuperHeroBlockComponent & { color: string | string[] }
): JSX.Element {
  const {
    content_position,
    mobile_background,
    mobile_background_alpha,
    tablet_background,
    tablet_background_alpha,
    desktop_background,
    desktop_background_alpha,
    color,
  } = props;

  const backgroundColor = useMemo(() => {
    // Keep the following order base, mobile, tablet and desktop
    const backgroundValues = [
      mobile_background,
      mobile_background,
      tablet_background,
      desktop_background,
    ];

    const alphaValues = [
      mobile_background_alpha,
      mobile_background_alpha,
      tablet_background_alpha,
      desktop_background_alpha,
    ];
    return backgroundValues.map((hex, index) => {
      const alpha = alphaValues[index];
      if (isNaN(alpha)) {
        return hex;
      }
      return convertHexToRgba(hex, alpha);
    });
  }, [
    mobile_background,
    tablet_background,
    desktop_background,
    mobile_background_alpha,
    tablet_background_alpha,
    desktop_background_alpha,
  ]);

  let blockProps: Record<string, string | string[]> = {
    top: ['0', '50%', '50%'],
    transform: ['0', 'translate(-50%, -50%)'],
    textAlign: 'center',
    backgroundColor,
  };

  if (content_position === 'middle-left') {
    blockProps = {
      ...blockProps,
      transform: ['none', 'translateY(-50%)'],
      left: ['0', '2.5%', '5%'],
      textAlign: ['center', 'left'],
    };
  } else if (content_position === 'middle-right') {
    blockProps = {
      ...blockProps,
      right: ['0', '5%', '5%'],
      transform: ['none', 'translateY(-50%)'],
      textAlign: ['center', 'right'],
    };
  } else {
    blockProps = { ...blockProps, left: ['0', '50%', '50%'] };
  }

  return (
    <Box
      position={['relative', 'absolute']}
      color={color}
      {...blockProps}
      width={['100%', '50%', '40%']}
      py={['1.7rem', 'min(100%, var(--space-5))', '12']}
      px={['1.7rem', '3', '5']}
      className="super-hero-block__content"
      data-testid="super-hero-content">
      {props.children}
    </Box>
  );
}

export function SuperHeroText(props: TextProps): JSX.Element {
  const Element = props.variant !== 'heading' ? Text : Heading;

  return (
    <Element
      mb={['4', '2', '4']}
      color="currentColor"
      {...props}
      className={`super-hero__text${
        props.className ? ` ${props.className}` : ''
      }`}
    />
  );
}

export function SuperHeroCTA(props: SuperHeroBlockComponent): JSX.Element {
  const { link_color, link_text, callToAction } = props;

  let buttonStyle: ButtonProps = { border: 'none' };

  if (link_color === 'button--outline') {
    buttonStyle = {
      variant: 'outline',
      color: 'currentColor',
      _active: {
        bg: 'none',
      },
    };
  } else if (link_color === 'button--alt') {
    buttonStyle = {
      colorScheme: 'white',
      variant: 'outline',
      color: 'green.500',
      borderColor: 'currentcolor',
      bg: 'white',
    };
  }

  return (
    <Button
      colorScheme="green"
      variant="solid"
      className="content-block-content__action"
      data-testid="super-hero-block-cta"
      onClick={callToAction}
      mt={['4', '2', '4']}
      textTransform="capitalize"
      borderRadius="sm"
      {...buttonStyle}>
      {link_text}
    </Button>
  );
}
